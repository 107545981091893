import { Typography } from "@material-ui/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { authState, contentState } from "../store/Reducer";
import Popup from "../component/Popup";
import {
  HCP,
  hcpRestricted,
  patient,
  patientRestricted,
} from "../constants/constants";
import { useTranslation } from "react-i18next";

interface AuthLinkProps {
  to: string;
  roles: Array<string>;
  children: string;
  className?: string;
  onClose: () => void;
}

export const AuthLink = ({
  to,
  children,
  roles,
  className = "",
  onClose,
}: AuthLinkProps) => {
  const currentRole = useSelector(authState)?.user?.roles || "";
  const content = useSelector(contentState);
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const handleClick = () => {
    if (
      !Array.isArray(roles) ||
      (roles.length === 0) ||
      roles.some((r) => currentRole.includes(r)) ||
      (roles.includes(patientRestricted) && currentRole.includes(patient)) ||
      (roles.includes(hcpRestricted) && currentRole.includes(HCP))
    ) {
      history.push(to);
      onClose();
    } else {
      setOpen(true);
    }
  };

  let path = "/login";

  if (Array.isArray(roles)) {
    path =
      roles.includes(HCP) || roles.includes(hcpRestricted)
        ? "/login/hcp"
        : "/login/patient";
  }

  return (
    <>
      <Typography onClick={handleClick} component="a" className={className}>
        {children}
      </Typography>
      <Popup
        open={open}
        onButton1={() => {
          setOpen(false);
        }}
        onButton2={() => {
          setOpen(false);
          onClose();
          history.push(path);
        }}
        title={t("restrictedPopup.title")}
        contentText={t("restrictedPopup.content")}
        button1Text={t("restrictedPopup.button.cancel.label")}
        button2Text={t("restrictedPopup.button.submit.label")}
        className={"restricted-popup"}
      />
    </>
  );
};
